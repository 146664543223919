import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Observable, Subscription, fromEvent } from 'rxjs';
import { CommonService } from './services/common/common.service';
import { MessagingService } from './services/messaging.service';
import { StorageService } from './services/storage/storage.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Rebecca';
  onlineEvent: Observable<Event>;
  offlineEvent: Observable<Event>;
  subscriptions: Subscription[] = [];
  notificationInterval: any;
  notificationData: any;
  showForegroundNotificationPopup: boolean = false;
  constructor(
    private _cdRef: ChangeDetectorRef,
    private _messagingService: MessagingService,
    private _common: CommonService,
    private _storage: StorageService,
  ) { }

  ngOnInit() {
    console.log('App is Running-->');
    this.chechStatus();
    if (this._storage.token) {
      this.updateFireBaseToken();
    }
  }


  updateFireBaseToken() {
    this._messagingService.requestPermission(this._storage.userId).then(token => {
      this._common.updateFirebaseToken(token);
    });
    this._messagingService.receiveMessage();
    this._messagingService.currentMessage.subscribe((res: any) => {
      console.log('Handling Foreground Push -->', res);
      if (res) {
        this.notificationData = res.data;
        this._storage.isNewNotification = true;
        this.showForeGroundPopUp();
      }
    });
  }

  showForeGroundPopUp() {
    if (this.showForegroundNotificationPopup) {
      clearTimeout(this.notificationInterval);
      this.showForegroundNotificationPopup = false;
      this._cdRef.detectChanges();
      setTimeout(() => {
        this.showForegroundNotificationPopup = true;
        this._cdRef.detectChanges();
      }, 700);
    } else {
      this.showForegroundNotificationPopup = true;
    }
    this._cdRef.detectChanges();
    this.notificationInterval = setTimeout(() => {
      this.showForegroundNotificationPopup = false;
      this._cdRef.detectChanges();
    }, 8000);
  }

  closeNotificationPopup() {
    this.showForegroundNotificationPopup = false;
    clearTimeout(this.notificationInterval);
    this._cdRef.detectChanges();
  }

  /**
   * @ONLINE_OFFLINE Get the online/offline status from browser window
   */
  chechStatus() {
    this.onlineEvent = fromEvent(window, 'online');
    this.offlineEvent = fromEvent(window, 'offline');

    this.subscriptions.push(this.onlineEvent.subscribe(e => {
      window.location.reload();
    }));
    this.subscriptions.push(this.offlineEvent.subscribe(e => {
    }));
  }

}
