export const ACCOUNT = 'account';
export const LOGIN = 'login';
export const FORGOT_PASSWORD = 'forgot-password';
export const RESET_PASSWORD = 'reset-password';
export const CHANGE_PASSWORD = 'change-password';
export const VERIFY_OTP = 'verify-otp';
export const ADD = 'add';
export const EDIT = 'edit';
export const DETAILS = 'details';
export const DELETE_ACCOUNT = 'delete';

export const PROFILE = 'profile';
export const DASHBOARD = 'dashboard';

export const ATHLETES = 'athletes';
export const ATHLETES_COACHES = 'coaches';
export const ATHLETES_PROGRESS = 'progress';
export const ATHLETES_APP_SCREEN_TIME = 'app-screen-time';
export const BULK_OPERATIONS = 'bulk-operations';
export const ASSIGN_BULK_OPERATIONS = 'assign-workout';
export const EDIT_BULK_OPERATIONS = 'edit-workout';
export const DELETE_BULK_OPERATIONS = 'delete-workout';


export const COACHES = 'coaches';
export const COACHES_ATHLETES = 'athletes';
export const ASSIGNED_WORKOUTS = 'assigned-workouts';
export const CREATED_WORKOUTS = 'created-workouts';

export const PAYMENTS = 'payments';
export const SUBSCRIPTION = 'subscription';

export const CATEGORIES = 'categories';

export const WORKOUTS = 'workouts';

export const DRILLS = 'drills';
export const NOTIFICATION = 'notifications';
export const PUSH_NOTIFICATION = 'push-notifications';

export const EARNINGS = 'earnings';

export const CMS = 'cms';
export const TERM_CONDITIONS = 'terms-&-conditions';
export const PRIVACY_POLICY = 'privacy-policy';
export const ABOUT_US = 'about-us';
export const CONTACT_US = 'contact-us';
export const FAQ = 'faq';





