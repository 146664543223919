export const OFFLINE = 'Connection lost! You are not connected to Internet';
export const ONLINE = 'Back to online';
export const NO_INTERNET = 'No Internet Connection';
export const TIME_OUT = 'Connection timed out. Please retry';
export const INTERNAL_SERVER_ERROR = "Couldn't connect to the server. Please try again";
export const INVALID_ID_ERROR = 'Invalid ID';
export const VERIFICATION_CODE = "Please enter verification code";
export const SOMETHING_WRONG = "Something went wrong, Please try again later";
export const NO_ACTION = "You can't perform this action";

export const ACCOUNT_ERROR_MESSAGES = {
  NAME_REQ: 'Please enter name',
  FIRST_NAME_REQ: 'Please enter first name',
  LAST_NAME_REQ: 'Please enter last name',
  MIN_NAME_REQ: 'Please enter atleast 3 characters',
  MOBILE_REQ: 'Please enter phone number',
  INVALID_MOBILE: 'Please enter a valid phone number',
  MIN_MOBILE: 'Please enter atleast 10 digit phone number',
  PASSWORD_REQ: "Please enter password",
  INVALID_PASSWORD: "Password must contain at least 1 uppercase, 1 lowercase, 1 number, 1 special character, minimum 8 and maximum 16 characters",
  NO_SPACE_PASSWORD: "Password can't start or end with a blank space",
  EMAIL_REQ: 'Please enter email address',
  INVALID_EMAIL: 'Please enter a valid email address',
  ONLY_ALPHABET: 'Only alphabets are allowed',
}

export const PASSWORD_ERROR_MESSAGES = {
  OLD_PASSWORD_REQ: "Please enter old password",
  INVALID_OLD_PASSWORD: "Old password must contain at least 1 uppercase, 1 lowercase, 1 number, 1 special character, minimum 8 and maximum 16 characters",
  NO_SPACE_OLD_PASSWORD: "Old password can't start or end with a blank space",
  NEW_PASSWORD_REQ: "Please enter new password",
  INVALID_NEW_PASSWORD: "New password must contain at least 1 uppercase, 1 lowercase, 1 number, 1 special character, minimum 8 and maximum 16 characters",
  NO_SPACE_NEW_PASSWORD: "New password can't start or end with a blank space",
  C_PASSWORD_REQ: "Please enter confirm new password",
  INVALID_C_PASSWORD: "Confirm new password must contain at least 1 uppercase, 1 lowercase, 1 number, 1 special character, minimum 8 and maximum 16 characters",
  NO_SPACE_C_PASSWORD: "Confirm new password can't start or end with a blank space",
  CONFORM_PASSWORD_MATCH_ERR: "New password and confirm new password not matched",
  OLD_NEW_PASSWORD_MATCH_ERR: "Old & New password can't be the same",
  WRONG_OLD_PASSWORD: "Entered old password was incorrect",
}

export const LISTING_COMMON_MESSAGES = {
  ACTIVE_TITLE: "Unblock Confirmation",
  ACTIVATE_TITLE: "Active Confirmation",
  BLOCK_TITLE: "Block Confirmation",
  INACTIVATE_TITLE: "Inactive Confirmation",
  DELETE_TITLE: "Delete Confirmation",
  ACTIVE_MSG: "Are you sure you want to unblock?",
  ACTIVATE_MSG: "Are you sure you want to activate?",
  BLOCK_MSG: "Are you sure you want to block?",
  INACTIVATE_MSG: "Are you sure you want to inactivate?",
  DELETE_MSG: "Are you sure you want to delete?",
}

export const EXERCISE_ERROR_MESSAGES = {
  TITLE_REQ: 'Please enter title',
  CATEGORY_REQ: 'Please select category',
  LEVEL_REQ: 'Please select difficulty level',
  BODY_PART_REQ: 'Please enter focused body part',
  CAL_BURN_REQ: 'Please enter calories burn during this drill',
  ZERO_ERROR: 'Value should be greater than Zero',
  VIDEO_REQ: 'Please select drill video'
}

export const BULK_WORKOUT_ASSIGN_ERROR_MESSAGES = {
  TITLE_REQ: 'Please select workout title',
  SKILL_REQ: 'Please select skill level',
  START_DATE_REQ: 'Please select workout start date',
  END_DATE_REQ: 'Please select workout end date',
  TIME_REQ: 'Please select workout timings',
  REPETITION_REQ: 'Please select workout repetition',
  WEEK_START_REQ: 'Please select starting day of week',
  INVALID_DATE: 'Please select valid date'
}

export const NOTIFICATION_ERROR_MESSAGES = {
  TITLE_REQ: 'Please enter title',
  DESCRIPTION_REQ: 'Please enter description',
  PLATFORM_REQ: 'Please select platform',
  USERS_REQ: 'Please select users',
}

export const WORKOUT_ERROR_MESSAGES = {
  TITLE_REQ: 'Please enter title',
  LEVEL_REQ: 'Please select difficulty level',
  CATEGORY_REQ: 'Please select category',
  EXERCISE_REQ: 'Please select atleast one drill',
  DES_REQ: 'Please enter description',
  COVER_IMG_REQ: 'Please select workout cover image'
}

export const MAX_IMG_SELECTION = 8;
export const MAX_IMG_SELECTION_ERR = (count = MAX_IMG_SELECTION) => `You can select ${count} images only`;


export const FAQ_ERROR_MESSAGES = {
  QUESTION_REQ: 'Please enter question',
  ORDER_REQ: 'Please enter question display order',
  INVALID_ORDER: 'Question display order should be greater than Zero',
  ANSWER_REQ: 'Please enter answer'
}

export const DURATION_CYCLE = [{ name: "Daily", value: "DAILY" }, { name: "Monthly", value: "MONTHLY" }];
export const MONTHS = [
  { name: "January", value: 1 },
  { name: "February", value: 2 },
  { name: "March", value: 3 },
  { name: "April", value: 4 },
  { name: "May", value: 5 },
  { name: "June", value: 6 },
  { name: "July", value: 7 },
  { name: "August", value: 8 },
  { name: "September", value: 9 },
  { name: "October", value: 10 },
  { name: "November", value: 11 },
  { name: "December", value: 12 }
]

export const DAYS_VALUE_IN_NUMBER = [0, 1, 2, 3, 4, 5, 6];
export const DAYS = [
  { name: "Sunday", value: DAYS_VALUE_IN_NUMBER[0] },
  { name: "Monday", value: DAYS_VALUE_IN_NUMBER[1] },
  { name: "Tuesday", value: DAYS_VALUE_IN_NUMBER[2] },
  { name: "Wednesday", value: DAYS_VALUE_IN_NUMBER[3] },
  { name: "Thursday", value: DAYS_VALUE_IN_NUMBER[4] },
  { name: "Friday", value: DAYS_VALUE_IN_NUMBER[5] },
  { name: "Saturday", value: DAYS_VALUE_IN_NUMBER[6] }
]
export const GRAPH_FILTER_ERROR = {
  SELECT_MONTH: "Please select month",
  SELECT_YEAR: "Please select year",
}

export const S3_BUCKET_ERROR = 'Network Failure or Sync up your system clock';
export const BULK_EROR = 'Please filter the athletes first on behalf of workout before performing the bulk operation';
export const ACTION_ERROR = (type) => `Please wait for ${type} action`;
export const TITLE_CONFIRMATION = (titleText) => `${titleText} Confirmation`;
export const MSSG_CONFIRMATION = (MssgText) => `Are you sure you want to ${MssgText}?`;

export const EDITOR_COMMON_MESSAGES = {
  CONTENT_REQ: 'Please fill some information about page',
  CMS_ACTION: (action: string) => `Content ${action} successfully`
}

export const DEFAULT_AGE_RANGE = [1, 100];
export const DEFAULT_AMOUNT_RANGE = [1, 100000];
export const DATE_TYPES = {
  SHORT_TIME: 'h:mm a',
  MEDIUM_DATE: 'MMM d, yyyy',
  FULL_DATE: 'EE, MMM d, yyyy',
  VALIDITY_TIME: 'MMM d, y - hh:mm:ss a',
  DATE_WITH_TIME: 'MMM d, y - hh:mm a',
}

export const LIST_TYPE = {
  LISTING: 'LISTING',
  CSV: 'CSV'
}

export const PLATFORM = [
  { name: "Admin", value: "Admin" },
  { name: "Application", value: "Application" }
]

export const USER_STATUS = [
  { name: "Active", value: "UN_BLOCKED" },
  { name: "Blocked", value: "BLOCKED" }
]

export const STATUS = [
  { name: "Active", value: "UN_BLOCKED" },
  { name: "Inactive", value: "BLOCKED" }
]

export const EARNING_STATUS_OBJECT = {
  SUCCESSFUL: "Successful",
  FAILED: "Failed"
}
export const EARNING_STATUS = [
  { name: "Successful", value: true },
  { name: "Failed", value: EARNING_STATUS_OBJECT.FAILED }
]

export const SUBSCRIPTION_TYPE = [
  { name: "Monthly", value: "MONTHLY" },
  { name: "Yearly", value: "YEARLY" }
]

export const SKILL_LEVELS = [
  { name: 'Grade School', value: 'GRADE_SCHOOL' },
  { name: 'Middle School', value: 'MIDDLE_SCHOOL' },
  { name: 'High School', value: 'HIGH_SCHOOL' },
  { name: 'College & Pro', value: 'COLLEGE_AND_PRO' }
]

export const WORKOUT_REPETITION = [
  { name: 'Daily', value: 'DAILY' },
  { name: 'Weekly', value: 'WEEKLY' },
  { name: 'Never', value: 'NEVER' }
]

export const SUBSCRIPTION_STATUS = [
  { name: "Yes", value: true },
  { name: "No", value: 'NO' }
]

export const DIFFICULTY_LEVELS = [
  { name: 'Beginner', value: 'BEGINNER' },
  { name: 'Intermediate', value: 'INTERMEDIATE' },
  { name: 'Advanced', value: 'ADVANCE' },
  { name: 'Pro', value: 'PRO' }
]

export const NOTIFICATION_PLATFORM = [
  { name: 'All', value: '4' },
  { name: 'Android', value: '1' },
  { name: 'iOS', value: '2' }
]

export const NOTIFICATION_USERS = [
  { name: 'All', value: 'ALL' },
  { name: 'Athletes', value: 'ATHLETE' },
  { name: 'Coaches', value: 'COACH' },
]

export const DETAIL_PAGE_SECTION = {
  COACHES: "COACHES",
  WORKOUTS: "WORKOUTS",
  ATHLETES: "ATHLETES",
  PROGRESS: "PROGRESS",
  ASSIGNED_WORKOUTS: "ASSIGNED_WORKOUTS",
  CREATED_WORKOUTS: "CREATED_WORKOUTS",
  PAYMENTS: "PAYMENTS",
  SUBSCRIPTIONS: "SUBSCRIPTIONS",
  APP_SCREEN_TIME: "APP_SCREEN_TIME"
}

export const SECTION_FOR_BULK_ACTION = {
  ASSIGNED_WORKOUT: "ASSIGNED_WORKOUT",
  EDIT_WORKOUT: "EDIT_WORKOUT",
  REMOVE_WORKOUT: "REMOVE_WORKOUT"
}

export const SUBSCRIBED_USER_TYPE_LIST = {
  ASSIGN_WORKOUT: "ASSIGN_WORKOUT",
  SUBSCRIBED_USER: "SUBSCRIBED_USER"
}

export const CONTENT_TYPES = {
  TERMS_AND_CONDITIONS: "TERMS_AND_CONDITIONS",
  PRIVACY_POLICY: "PRIVACY_POLICY",
  CONTACT_US: "CONTACT_US",
  ABOUT_US: "ABOUT_US",
  FAQ: "FAQ"
}
