import { ACCOUNT, DASHBOARD } from './../../constants/routes';
import { HttpService } from './../http/http.service';
import { Injectable } from '@angular/core';
import { USER_INFO_API } from '../../constants/api-end-point';
import { userId } from '../../constants/storage-keys';
import { Router } from '@angular/router';
import { CommonService } from '../common/common.service';
import { MessagingService } from '../messaging.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  profileDetail: any;
  isNewNotification = false;
  constructor(
    private _http: HttpService,
    private _router: Router,
    private _common: CommonService,
    private _messagingService: MessagingService,
  ) { }

  setUserToken(token) {
    localStorage.setItem('session_token', token);
  }

  get token() {
    return localStorage.getItem("session_token");
  }

  get userId() {
    return atob(localStorage.getItem(userId));
  }

  logout() {
    localStorage.clear();
    setTimeout(() => {
      this.profileDetail = null;
    }, 1000); // this is used for avoid random reflection on side bar if logout
    this._router.navigate([ACCOUNT]);
  }

  loginSuccessfully(response: any, responeFrom = '') {
    this.setUserToken(response.data.accessToken);
    localStorage.setItem(userId, btoa(response.data._id));
    this.askNotificationPermission();
    this._router.navigate([DASHBOARD]);
  }

  getProfileDetail(showLoader = true) {
    return new Promise((resolve, reject) => {
      if (this.profileDetail) {
        resolve(this.profileDetail);
      } else {
        this._http.get(USER_INFO_API, '', showLoader).toPromise().then((response: any) => {
          response.data.profilePicture = environment.MEDIA_SHOW_URL_S3+response.data.profilePicture;
          this.profileDetail = response.data;
          this.isNewNotification = response.data.newNotifications;
          resolve(this.profileDetail);
        }, error => {
          reject(error);
        });
      }
    });
  }

  deviceDetail(info?: number) {
    /*---1=device_token, 2=deviceId, 3=platform---*/
    switch (info) {
      case 1:
        let deviceToken = this.attachDeviceToken();
        return deviceToken;
      case 2:
        let deviceId = this.randomDeviceId();
        return deviceId;
      case 3:
        return "3";
      case 4:
        return this.getTimeZone();
    }
  }

  attachDeviceToken() {
    return (Date.now() + Math.floor(Math.random() * 1000000) + 1).toString();
  }

  randomDeviceId() {
    return (window.navigator.userAgent.replace(/\D+/g, ''));
  }

  getTimeZone() {
    let date = new Date();
    let offset = date.getTimezoneOffset() * -1;
    return offset * 60 * 1000;
  }

  askNotificationPermission() {
    this._messagingService.requestPermission(this.userId).then(token => {
      if (token) {
        this._common.updateFirebaseToken(token);
      }
    });
  }

}
