// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  name: "dev",
  API_BASE_PATH: "https://rebeccadevapi.appskeeper.in/api/v1/",
  // API_BASE_PATH: "https://rebeccastgapi.appskeeper.in/api/v1/",
  API_KEY: "ZUtTl7UnXU",
  USERNAME: "YmR7Rm70Ert0@3Jej&#M",
  PASSWORD: "wQ1TaW5LGcXxwys@2pns",
  FINGER_PRINT: "Basic Y29hY2hlczpjb2FjaGVzQDEyMw==",
  COUNTRY_CODE: "+1",
  MEDIA_SHOW_URL_S3: "https://app-development.s3.amazonaws.com/",
  s3: {
    AWS_ACCESS_KEY: 'AKIAQUD4MO3QRLE6O6YZ',
    AWS_SECRET_KEY: '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',
    REGION: 'us-east-1',
    BUCKET_NAME: 'app-development/rebecca/angular'
  },
  firebaseConfig: {
    apiKey: "AIzaSyD5m2pS33HjqpNhjlWaywq75KGIWbnzr6g",
    authDomain: "lethal-shooter.firebaseapp.com",
    databaseURL: "https://lethal-shooter-default-rtdb.firebaseio.com",
    projectId: "lethal-shooter",
    storageBucket: "lethal-shooter.appspot.com",
    messagingSenderId: "630510512922",
    appId: "1:630510512922:web:c91e65169fe1d7f5f01aa3"
  }

}


