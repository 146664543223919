<rc-loader></rc-loader>
<ng-container *ngIf="showForegroundNotificationPopup">
  <div class="push-notification-cover">
    <div class="push-notification-content">
      <a [href]="notificationData?.link">
        <div class="single-notification">
          <div class="img-cover">
            <img [src]="'assets/images/logo.png'" alt="Profile">
          </div>
          <div class="textual-cover">
            <p>{{notificationData?.title}}</p>
            <p>{{notificationData?.body}}</p>
          </div>
        </div>
      </a>
      <span class="icon material-icons close-icon" (click)="closeNotificationPopup()">close</span>
    </div>
  </div>
</ng-container>
<rc-toast-notification></rc-toast-notification>
<router-outlet></router-outlet>
