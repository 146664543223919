import { Injectable } from '@angular/core';
import { INVALID_ID_ERROR } from '../../constants/messages';
import { Router } from '@angular/router';
import { infoKey, forgotScreen, profileScreen, signUpScreen, loginScreen, deleteScreen } from '../../constants/storage-keys';
import { ACCOUNT, VERIFY_OTP } from '../../constants/routes';
import { Subscription } from 'rxjs';
import { ToastService } from '../../components/toast-notification/toast.service';
import { HttpService } from '../http/http.service';
import {
  CATEGORY_API, EXERCISES_FOR_WORKOUT_API,
  CMS_API, FIREBASE_TOKEN_UPDATE, USER_NOTIFICATIONS_API,
  USER_NOTIFICATION_READ_STATUS_API,
  WORKOUT_LIST_API
} from '../../constants/api-end-point';
import { Location } from '@angular/common';
import { HighChartService } from '../high-chart/high-chart.service';


@Injectable({
  providedIn: 'root'
})
export class CommonService {

  forForgotInfo = {} || null;
  forDeleteAccountInfo = {} || null;
  isActiveClass = false;
  myChartOptions: any = {};
  isSetStatus = false;
  individuallySubscribed = false;

  constructor(
    private _toast: ToastService,
    private _http: HttpService,
    private _loc: Location,
    private _highChartService: HighChartService,
    private _router: Router) { }

  getCategories(query = { pageNo: 1, limit: 100, sortBy: 'name', sortOrder: 1, status: 'UN_BLOCKED' }) {
    return this._http.get(CATEGORY_API, query, false);
  }

  getWorkoutListInOtherModules(query = { pageNo: 1, limit: 100, sortBy: 'title', sortOrder: 1 }, showLoader = false) {
    return this._http.get(WORKOUT_LIST_API, query, showLoader);
  }

  getExercisesForWorkouts(query?) {
    return this._http.get(EXERCISES_FOR_WORKOUT_API, query, false);
  }

  getAllNotifications(query) {
    return this._http.get(USER_NOTIFICATIONS_API, query, false);
  }

  getCmsContents(type, query = {}, showLoader = false) {
    return this._http.get(CMS_API(type), query, showLoader);
  }

  locationBack() {
    this._loc.back();
  }

  scrollTop() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  addActiveClass() {
    setTimeout(() => {
      this.isActiveClass = true;
    }, 0);
  }


  updateFirebaseToken(token) {
    const firebaseObj = {
      token
    }
    this._http.post(FIREBASE_TOKEN_UPDATE, token ? firebaseObj : {}, false).pipe().subscribe(response => { });
  }

  removeActiveClass() {
    this.isActiveClass = false;
  }

  isValidId(ID: string, isNavigate = true) {
    if ((/^[a-f\d]{24}$/i.test(ID))) {
      return true;
    } else {
      this._toast.error(INVALID_ID_ERROR);
      if (isNavigate) {
        this.navigate(['404']);
      } else {
        return false;
      }
    }
  }

  setOtpRelatedInfo(obj, screen, isNavigate = true) {
    switch (screen) {

      case atob(loginScreen):
        this.setScreenInfo(loginScreen);
        break;

      case atob(signUpScreen):
        this.setScreenInfo(signUpScreen);
        break;

      case atob(forgotScreen):
        this.setScreenInfo(forgotScreen);
        break;

      case atob(profileScreen):
        this.setScreenInfo(profileScreen);
        break;

      case atob(deleteScreen):
        this.setScreenInfo(deleteScreen);
        break;
  
      default:
        break;
    }
    localStorage.setItem(infoKey, btoa(JSON.stringify(obj)));
    if (isNavigate) {
      this.navigate([ACCOUNT, VERIFY_OTP]);
    }
  }

  setScreenInfo(screenNameValue) {
    localStorage.setItem(screenNameValue, screenNameValue);
  }

  addClassOnBody(className) {
    document.body.classList.add(className);
  }

  removeClassOnBody(className) {
    document.body.classList.remove(className);
  }

  scrollViewById(elementId) {
    var myElement = document.getElementById(elementId);
    myElement.scrollIntoView();
  }

  redirectToAnotherTab(url) {
    const anchor = document.createElement("a");
    anchor.setAttribute("href", url);
    anchor.setAttribute("target", "_blank");
    anchor.click();
  }

  navigate(route: any, query = {}, isMergeQuery = false) {
    this._router.navigate(route, { queryParams: query, queryParamsHandling: isMergeQuery ? 'merge' : '' })
  }

  plotGraph(nativeElement: any, response: any, chartConfig: any) {
    let series = [];
    let dataSets = {
      color: "#d09b29"
    }

    if ("valueArray" in response.data) {
      dataSets["name"] = chartConfig.headingText;
      dataSets["data"] = response.data.valueArray;
      series.push(dataSets);
    }

    this.setGraphData(nativeElement, response.data.keyArray, series, chartConfig);
  }

  setGraphData(nativeElement: any, labelArray: [], series: any, chartConfig: any) {
    this.myChartOptions = {
      chart: {
        type: chartConfig.chartType
      },
      title: {
        text: chartConfig.headingText
      },
      xAxis: {
        categories: labelArray,
        reversed: false,
        crosshair: true
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0
        }
      },
      series: series
    }
    this.myChartOptions['yAxis'] = {
      allowDecimals: false,
      min: 0,
      title: {
        text: chartConfig.yAxisTitleText
      }
    }
    this.myChartOptions['tooltip'] = {
      headerFormat: '<span style="font-size:20px">In {point.key}</span><table>',
      pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
        '<td style="padding:0"><b>{point.y}</b></td></tr>',
      footerFormat: '</table>',
      shared: true,
      useHTML: true
    }
    this._highChartService.createChart(nativeElement, this.myChartOptions);
  }

  /**
  * @UNSUBSCRIPTION Unsubscribe all subscriptions to avoid memory leak
  */
  unsubscribe(subscriptions: Subscription[]) {
    subscriptions.forEach(subscription => subscription.unsubscribe());
  }

}
