import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { take } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { CommonService } from './common/common.service';

@Injectable({ providedIn: 'root' })

export class MessagingService {

  currentMessage = new BehaviorSubject(null);

  constructor(
    private _common: CommonService,
    private angularFireDB: AngularFireDatabase,
    private angularFireAuth: AngularFireAuth,
    private angularFireMessaging: AngularFireMessaging) {
    // this.listenNotification();
  }

  listenNotification() {
    this.angularFireMessaging.messages.subscribe((_messaging: any) => {
      _messaging.onMessage = _messaging.onMessage.bind(_messaging);
      _messaging._next = (payload: any) => {
        if (payload) {
          const notification = payload;
          notification["showPopup"] = true;
          this.currentMessage.next(notification);
        }
      };
      _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);

      //when push comes from background and it also show custom push on foreground
      const channel = new BroadcastChannel("sw-messages");
      channel.addEventListener("message", event => {
        if (event) {
          const notification = event.data;
          notification["showPopup"] = false;
          this.currentMessage.next(notification);
        }
      })
    })
  }

  /**
    * request permission for notification from firebase cloud messaging
    *
    * @param userId userId
  */
  requestPermission(userId) {
    return new Promise((resolve, reject) => {
      this.angularFireMessaging.requestToken.subscribe((token) => {
        if (token) {
          this.updateToken(userId, token);
          resolve(token);
        } else {
          this.updateFirebaseTokenForBlock('');
        }
      }, (err) => {
        // reject(err.message)
        this.updateFirebaseTokenForBlock('');
      });
    })
  }

  /**
   * update token in firebase database
   *
   * @param userId userId as a key
   * @param token token as a value
   */
  updateToken(userId, token) {
    // we can change this function to request our backend service
    this.angularFireAuth.authState.pipe(take(1)).subscribe(
      () => {
        const data = {};
        data[userId] = token
        this.angularFireDB.object('fcmTokens/').update(data)
      })
  }

  /**
   * hook method when new notification received in foreground
   */
  receiveMessage() {
    this.angularFireMessaging.messages.subscribe((payload: any) => {
      this.currentMessage.next(payload);
    })
  }

  updateFirebaseTokenForBlock(token) {
    this._common.updateFirebaseToken(token);
  }

}
