import { Injectable } from '@angular/core';
import { StorageService } from '../../storage/storage.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private _storage: StorageService
  ) { }

  authorization() {
    const token = this._storage.token;
    const auth = {
      'Authorization': token ? 'Bearer ' + token : 'Basic ' + btoa(environment.USERNAME + ':' + environment.PASSWORD),
      "api_key": environment.API_KEY,
      "platform": '' + this._storage.deviceDetail(3),
      "timezone": '' + this._storage.deviceDetail(4),
      "language": 'en',
      'Content-Type': "application/json",
      'Access-Control-Allow-Origin': '*',
    }
    return auth;
  }

  imageauthorization() {
    const token = this._storage.token;
    const auth = {
      'accept': "application/json",
      'Authorization':  'Basic ' + btoa(environment.USERNAME + ':' + environment.PASSWORD),
      "api_key": environment.API_KEY,
      "platform": '' + this._storage.deviceDetail(3),
      "timezone": '' + this._storage.deviceDetail(4),
      "language": 'en',
      'Access-Control-Allow-Origin': '*',
    }
    return auth;
  }

}
