export const infoKey = btoa('phoneInfo');
export const signUpScreen = btoa('signup');
export const forgotScreen = btoa('forgot');
export const deleteScreen = btoa('deleteAccount');
export const profileScreen = btoa('profile');
export const loginScreen = btoa('login');
export const changeNumberScreen = btoa('changeNumber');
export const signupToken = btoa('signupToken');
export const userId = btoa('userId');
export const sessionId = btoa('sessionId');








