const ADMIN_BASE: string = 'superUser';
const USER_BASE: string = 'user';
const CATEGORY_BASE: string = 'categories';
const ATHLETE_BASE: string = 'athlete';
const COACH_BASE: string = 'coach';
const WORKOUT_BASE: string = 'workout';
const EXERCISE_BASE: string = 'exercise';
const EARNING_BASE: string = 'earnings';
const STATIC_CONTENT_BASE: string = 'contents';
const COMMON_BASE: string = 'common';


/**
 * @ACCOUNT_RELATED_END_POINTS
 */
export const LOGIN_API = `${ADMIN_BASE}/login`;
export const LOGOUT_API = `${ADMIN_BASE}/logout`;
export const FORGOT_PASSWORD_API = `${ADMIN_BASE}/forgot-password`;
export const RESET_PASSWORD_API = `${ADMIN_BASE}/reset-password`;
export const CHANGE_PASSWORD_API = `${ADMIN_BASE}/change-password`;
export const VERIFY_OTP_API = `${ADMIN_BASE}/verify-otp`;
export const RESEND_OTP_API = `${ADMIN_BASE}/send-otp`;
export const USER_INFO_API = `${ADMIN_BASE}/profile`;
export const FIREBASE_TOKEN_UPDATE = `${ADMIN_BASE}/update-notification-setting`;
export const DELETE_ACCOUNT_API = `${USER_BASE}/facebook/delete-user-data`;

/**
 * @CATEGORY_RELATED_END_POINTS
 */
export const CATEGORY_API = `${CATEGORY_BASE}`;

/**
 * @DASHBOARD_RELATED_END_POINTS
 */
export const DASHBOARD_API = `${ADMIN_BASE}/dashboard`;

/**
 * @ATHLETE_RELATED_END_POINTS
 */
export const ATHLETE_LIST_API = `${ADMIN_BASE}/${ATHLETE_BASE}/list`;
export const ATHLETE_DETAILS_API = `${ADMIN_BASE}/${ATHLETE_BASE}/details`;
export const ATHLETE_LIST_ON_DETAILS_API = `${ADMIN_BASE}/athlete`;
export const ATHLETE_LIST_BULK_OPERATION_API = `${ADMIN_BASE}/assign-workout/athlete/list`;
export const ATHLETE_ACTION_API = `${ADMIN_BASE}/${ATHLETE_BASE}/blocked-unblocked-delete`;
export const SUBSCRIBED_ATHLETE_ASSIGNED_WORKOUT_API = `${ADMIN_BASE}/subscribed-athlete-workout`;

/**
 * @COACH_RELATED_END_POINTS
 */
export const COACH_LIST_API = `${ADMIN_BASE}/${COACH_BASE}/list`;
export const COACH_ADD_API = `${ADMIN_BASE}/add-coaches`;
export const COACH_DETAILS_API = `${ADMIN_BASE}/${COACH_BASE}/get-coach-details`;
export const COACH_ACTION_API = `${ADMIN_BASE}/remove-coaches`;

/**
 * @EXERCISE_RELATED_END_POINTS
 */
export const EXERCISE_LIST_API = `${ADMIN_BASE}/${EXERCISE_BASE}/list`;
export const EXERCISE_ADD_EDIT_API = `${ADMIN_BASE}/${EXERCISE_BASE}`;
export const EXERCISE_ACTION_API = `${ADMIN_BASE}/${EXERCISE_BASE}/action`;
export const EXERCISE_DETAILS_API = `${ADMIN_BASE}/${EXERCISE_BASE}/details`;
export const EXERCISE_MEDIA_UPLOAD_API = `${COMMON_BASE}/media-upload`;

/**
 * @WORKOUT_RELATED_END_POINTS
 */
export const WORKOUT_LIST_API = `${ADMIN_BASE}/${WORKOUT_BASE}/list`;
export const WORKOUT_API = `${ADMIN_BASE}/${WORKOUT_BASE}`;
export const EXERCISES_FOR_WORKOUT_API = `${ADMIN_BASE}/active-exercise`;
export const WORKOUT_DETAILS_API = `${ADMIN_BASE}/${WORKOUT_BASE}/details`;

/**
 * @EARNING_RELATED_END_POINTS
 */
export const EARNING_LIST_API = `${ADMIN_BASE}/${EARNING_BASE}/list`;

/**
 * @BROADCAST_NOTIFICATION_RELATED_END_POINTS
 */
export const NOTIFICATION_API = `${ADMIN_BASE}/notifications/list`;
export const NOTIFICATION_ADD_EDIT_API = `${ADMIN_BASE}/notification-added`;
export const NOTIFICATION_EDIT_API = `${ADMIN_BASE}/notification-edit`;
export const NOTIFICATION_ACTION_API = `${ADMIN_BASE}/notification-deleted`;
export const NOTIFICATION_DETAILS_API = `${ADMIN_BASE}/get-notifications-details`;

/**
 * @SETTING_RELATED_END_POINTS
 */
export const USER_PREFERENCES_API = `${USER_BASE}/update-notification-status`;
export const USER_NOTIFICATIONS_API = `${COMMON_BASE}/notifications`;
export const USER_NOTIFICATION_READ_STATUS_API = `${USER_BASE}/notification/update-read-status`;

/**
 * @CMS_RELATED_END_POINTS
 */
export const CMS_API = (type) => `${STATIC_CONTENT_BASE}/${type}`;
export const UPDATE_CMS_API = `${STATIC_CONTENT_BASE}`;
export const FAQ_API = `${STATIC_CONTENT_BASE}/faq`;

